import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'wcDate', standalone: true })
export class WebcoffeeDatePipe implements PipeTransform {
    transform(date: DateTime | string | string[], format?: string): string | number {
        if (typeof date === 'string') {
            if (format === 'human') {
                return DateTime.fromISO(date).setZone('Europe/Bucharest').toRelative({ locale: 'ro' });
            }
            if (format === 'millis') {
                return DateTime.fromISO(date).setZone('Europe/Bucharest').toMillis();
            } else {
                const parsedDate = DateTime.fromISO(date).setZone('Europe/Bucharest');

                return parsedDate.isValid ? parsedDate.toFormat(format ? format : 'dd.MM.yyyy HH:mm') : DateTime.fromMillis(+date).toFormat(format ? format : 'dd.MM.yyyy HH:mm');
            }
        } else if (DateTime.isDateTime(date)) {
            if (format === 'human') {
                return date.setZone('Europe/Bucharest').toRelative({ locale: 'ro' });
            }
            if (format === 'millis') {
                return date.setZone('Europe/Bucharest').toMillis();
            } else {
                return date.setZone('Europe/Bucharest').toFormat(format ? format : 'dd.MM.yyyy HH:mm');
            }
        } else {
            return date
                ? date
                      .map((date) =>
                          DateTime.fromISO(date as string)
                              .setZone('Europe/Bucharest')
                              .toFormat(format ? format : 'dd.MM.yyyy HH:mm'),
                      )
                      .join(', ')
                : '';
        }
    }
}
